<template>
<q-d-b-c-e mode="create"></q-d-b-c-e>
</template>

<script>
import QDBCE from "@/pages/QuestionDB/components/QDBCE";
export default {
name: "QDBCreate",
  components: {QDBCE}
}
</script>

<style scoped>

</style>
