import VueRouter from "vue-router";
import routes from "@/config/routes";
import {getToken, getUID} from "@/utils/token";

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let white_route = ['/sign/in', '/sign/up']
  if ((!getToken("access_token") || !getUID()) && !white_route.includes(to.path))
    next({path: '/sign/in'})
  else
    next()
})
export default router;
