<template>
  <div class="home">
    <header>
      <h1># Doti</h1>
    </header>
    <div id="card-list">
      <div v-show="loading === false && quesDBList.length===0" class="card">
        <van-empty description="你似乎来到了没有知识存在的荒原"/>
        <vs-button block flat success @click="$router.push({path:'/qdb/create'})">新的起点从创建题库开始吧</vs-button>
      </div>

      <div v-for="(db, dbIndex) in quesDBList" :key="dbIndex" class="card-contain">
        <div class="c-card">
          <div class="c-card-cover">
            <img :src="require(`@/static/img/default-cover${dbIndex%10}.jpg`)"
                 alt="封面" class="c-card-cover-img" @click="$router.push({path:'/qdb/edit',query: {id: db['node']['id']}})">
          </div>
          <div class="c-card-right">
            <h3>{{ db['node']['name'] }}</h3>
            <div class="van-multi-ellipsis--l2 description">{{ db['node']['description'] }}</div>
            <div class="c-card-button-group">
              <vs-button danger floating
                         @click="$router.push({path: '/test-mode/large', query: {id: db['node']['id']}})">
                <box-icon color='#ffffff' floating name='collection' type='solid'></box-icon>
                学习
              </vs-button>
              <vs-button floating shadow @click="$router.push({path: '/qdb/q/list',query: {id: db['node']['id']}})">
                <box-icon floating name='list-ol'></box-icon>
                管理
              </vs-button>
            </div>
          </div>
        </div>
        <!--        <vs-card type="4">-->
        <!--          <template #title>-->
        <!--            <h2 @click="$router.push({path:'/qdb/edit',query: {id: db['node']['id']}})">{{ db['node']['name'] }}</h2>-->
        <!--          </template>-->
        <!--          <template #text>-->

        <!--            <p class="description" @click="$router.push({path:'/qdb/edit',query: {id: db['node']['id']}})">-->
        <!--              {{ db['node']['description'] }}-->
        <!--            </p>-->

        <!--          </template>-->
        <!--          <template #img>-->
        <!--            <img :src="require(`@/static/img/default-cover${dbIndex%10}.jpg`)"-->
        <!--                 alt="db['node']['name']" @click="$router.push({path:'/qdb/edit',query: {id: db['node']['id']}})">-->
        <!--          </template>-->
        <!--          <template #interactions>-->
        <!--            <vs-button danger floating @click="$router.push({path: '/test-mode/large', query: {id: db['node']['id']}})">-->
        <!--              <box-icon color='#ffffff' floating name='collection' type='solid'></box-icon>-->
        <!--              学习-->
        <!--            </vs-button>-->
        <!--            <vs-button floating shadow @click="$router.push({path: '/qdb/q/list',query: {id: db['node']['id']}})">-->
        <!--              <box-icon floating name='list-ol'></box-icon>-->
        <!--              管理-->
        <!--            </vs-button>-->
        <!--          </template>-->
        <!--        </vs-card>-->

      </div>

    </div>
  </div>
</template>

<script>

import {getUID} from "@/utils/token";
import {questionDatabaseList} from "@/graphql/questiondb";

export default {
  name: "Home",
  props: {
    id: {}
  },
  data() {
    return {
      loading: true,
      quesDBList: []
    }
  },
  async created() {
    await this.get()
    this.loading = false
  },
  methods: {
    async get() {
      let uid = getUID()
      if (uid) {
        await this.$apollo.query({
          query: questionDatabaseList,
          variables: {
            user: uid
          },
          fetchPolicy: "no-cache",
        }).then(({data}) => {
          this.quesDBList = data['questionDatabaseList']['edges']
        })
      }
    }
  }
}
</script>

<style scoped>
.btn-text {
  padding-left: 5px;
  font-weight: 700;
}
header {
  padding: 0 1rem;
  color: #1e1e1e;

}


.home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

.card-contain {
  margin: 0 auto 1.5rem auto;
  background: #ffffff;
  padding: 8px;
  border-radius: 20px 5px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
  display: flex;
  align-items: center;
  flex-direction: column;
}

#card-list {
  display: grid;
  padding: 20px 10px;
}

@media screen and (min-width: 410px) {
  #card-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 820px) {
  #card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1230px) {
  #card-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1640px) {
  #card-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 2050px) {
  #card-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 2460px) {
  #card-list {
    grid-template-columns: repeat(6, 1fr);
  }
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 35px 35px 35px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.description {
  /*width: 100px;*/
  /*display: -webkit-box;*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis;*/
  /*-webkit-line-clamp: 6;*/
  /*-webkit-box-orient: vertical;*/
}

.c-card-cover {
  width: 8rem;
  height: 8rem;
  border-radius: 20px;
}

.c-card {
  width: 340px;
  display: flex;
  border-radius: 20px;
  padding: 6px 8px;
  align-items: center;
  background: #fff;
  box-shadow: 0 15px 30px -8px rgba(0, 0, 0, 0.08);
}

.c-card-cover-img {
  width: 100%;
  border-radius: inherit;
  min-width: 8rem;
}

.c-card-button-group {
  display: flex;
}

.c-card-right {
  width: calc(100% - 100px);
  background: transparent;
  padding: 15px 20px 0 23px;
}

.c-card-right h3 {
  color: rgb(44, 62, 80);
  margin: 0;
  font-size: 1.1rem;
  padding: 0;
}

.c-card-right .description {
  font-size: 0.8rem;
  opacity: 0.8;
  line-height: 1.7;
  color: rgb(44, 62, 80);
  margin: 0 0 10px 0;
}

.c-card-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
