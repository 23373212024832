//API
import {Plugins, StatusBarStyle,} from '@capacitor/core';

const {StatusBar} = Plugins;

export function setStatusBar() {
  StatusBar.setStyle({
    style: StatusBarStyle.Light
  });
  StatusBar.setBackgroundColor({
    color: "#f4f7f8"
  })
}

export class StatusBarInstance {


  hideStatusBar() {
    StatusBar.hide();
  }

  showStatusBar() {
    StatusBar.show();
  }
}
