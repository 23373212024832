<template>
  <div id="app">
    <van-loading v-if="$apollo.loading"/>
    <vue-page-transition name="fade">
      <router-view/>
    </vue-page-transition>
    <br/>
    <br/>
    <van-tabbar @change="tabbarChange()" v-show="!noNav.main.includes($route.path)" :border="false" route>
      <van-tabbar-item v-for="(t, tIndex) in tabbar" :key="tIndex" :to="t.to">
        <template #icon="props">
          <img :src="props.active?t.ac:t.unac">
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>

</template>

<script>

import {setStatusBar} from "@/utils/statusBar";
import {Capacitor} from '@capacitor/core';

export default {
  name: 'App',
  data() {
    return {
      tabbarActive: 0,
      activeSidebar: false,
      activeSidebarItem: 'HOME',
      tabbar: [
        {
          to: "/",
          ac: require(`./static/img/home-a.png`),
          unac: require(`./static/img/home.png`)
        },
        {
          to: "/more",
          ac: require(`./static/img/command-a.png`),
          unac: require(`./static/img/command.png`),
        },
        {
          to: "/store/list",
          ac: require(`./static/img/cabinet-a.png`),
          unac: require(`./static/img/cabinet.png`)
        }
      ],
      noNav: {
        main: ['/sign/in', '/sign/up'],
        back: ['/'],
        home: ['/'],
      }
    }
  },
  created() {
    if (Capacitor.getPlatform() === 'android') {
      setStatusBar()
    }
  },
  methods: {
    tabbarChange(){

    }
  }
}
</script>

<style>

</style>
