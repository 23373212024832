<template>
  <div class="contain">
    <header>
      <h1># 登录</h1>
    </header>
    <div class="card">
      <div class="item">
        <span class="subheading">
        用户名
      </span>
        <vs-input v-model="username" placeholder="4-16个字母/汉字/数字/_">
          <template #icon>
            <box-icon name='at'></box-icon>
          </template>
        </vs-input>
      </div>
      <div class="item">
        <div class="subheading">
          密码
        </div>
        <vs-input v-model="password" type="password">
          <template #icon>
            <box-icon name='lock'></box-icon>
          </template>
        </vs-input>
      </div>
    </div>
    <div class="center">
      <vs-button floating @click="signIn">
        <box-icon color='#ffffff' name='log-in'></box-icon>
        <span class="btn-text">登 录</span></vs-button>
    </div>
    <div class="center top-4">
      <vs-button flat success @click="$router.push({path: '/sign/up'})">没有账号？立即注册</vs-button>
    </div>

  </div>
</template>

<script>
import gql from "graphql-tag";
import {setTokens, usernameRegex} from "@/utils/token";
import {signIn} from "@/pages/Sign/graphql";

export default {
  name: "SignIn",
  data() {
    return {
      username: "",
      password: ""
    }
  },
  methods: {
    signIn() {
      const username = this.username
      const password = this.password
      if (!usernameRegex.exec(username)) {
        this.$toast("用户名不太正确哦😮️")
        return
      }
      if (password === "" || password === null) {
        this.$toast("密码不能为空哦😐️")
        return;
      }
      this.username = ""
      this.password = ""
      this.$apollo.mutate({
        mutation: signIn,
        variables: {
          username: username,
          password: password,
        },
      }).then(async ({data}) => {
        setTokens(data['tokenAuth']['token']);
        await this.getUID()
        this.$toast("登录成功🚀️")
      }).catch(() => {
        this.$toast("登录不太正常🙍🏻")
      })
    },
    /**
     * 获取用户id
     */
    async getUID() {
      await this.$apollo.query({
        query: gql`query {userId}`,
        fetchPolicy: 'no-cache',
      }).then(async ({data}) => {
        await localStorage.setItem("uid", data['userId'])
        this.$router.replace({
          path: "/"
        })
      })
          .catch(() => {
            this.$toast("登录不成功🙈️")
          })
    },
  },
  apollo: {}
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.stem {
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}


.subheading {
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgb(44, 62, 80);
  margin-top: 0;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.top-4 {
  margin-top: 4rem;
}
</style>
