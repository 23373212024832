<template>
  <q-c-e mode="edit"></q-c-e>
</template>

<script>
import QCE from "@/pages/Question/components/QCE";
export default {
  name: "QEdit",
  components: {QCE},
}
</script>

<style scoped>

</style>
