import {gql} from "apollo-boost";

export const userQuestionListNew = gql`query ($qdbid: ID!, $uid: ID!){
    userQuestionListNew(questiondb: $qdbid, user: $uid){
        edges{
            node{
                id,
                memoryGrade,
                question{
                    id,
                    stem,
                    answer
                }
            }
        }
    }
}`
export const userQuestionListAll = gql`query ($qdbid: ID!, $uid: ID!){
    userQuestionListAll(questiondb: $qdbid, user: $uid){
        edges{
            node{
                id,
                memoryGrade,
                question{
                    id,
                    stem,
                    answer
                }
            }
        }
    }
}`

export const userQuestionList = gql`query ($qdbid: ID!, $uid: ID!,$memoryGrade: String){
    userQuestionList(questiondb: $qdbid, user: $uid, memoryGrade: $memoryGrade){
        edges{
            node{
                id,
                memoryGrade,
                question{
                    id,
                    stem,
                    answer
                }
            }
        }
    }
}`
export const userQuestionPatch = gql`mutation ($uqid: ID! , $memoryGrade: UserQuestionMemoryGradeInput){
    userQuestionPatch(id: $uqid, input: {memoryGrade: $memoryGrade}){
        userQuestion{
            id
        }
    }
}`
export const userQuestionBatchPatch = gql`mutation ($input:[BatchPatchUserQuestionInput]!){
    userQuestionBatchPatch(input: $input){
        __typename
    }
}`
