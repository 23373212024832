<template>
  <div class="learn-mode">
    <header>
      <h1># 学习模式</h1>
    </header>
    <div class="card">
      <h2 class="subheading">常规</h2>
      <div class="item">
        <vs-button v-for="(item, index) in common_mode" :key="index" circle flat size="large"
                   @click="$router.push({path:'/test', query: {id: id, mode: item.mode}})">
          <box-icon :name='item.icon' color='#195bff'></box-icon>
          {{ item.name }}{{ count[item.mode] }}
        </vs-button>
      </div>
    </div>
    <div class="card">
      <h2 class="subheading">熟悉程度</h2>
      <div class="item">
        <vs-button v-for="(item, index) in level_mode" :key="index" :color="item.color" floating
                   size="large"
                   @click="$router.push({path:'/test', query: {id: id, mode: item.mode}})">
          <box-icon :name='item.icon' color='#fff'></box-icon>
          {{ item.name }}{{ count[item.mode] }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import {userQuestionListAllCount, userQuestionListCount, userQuestionListNewCount} from "@/graphql/userQuestion";
import {getUID} from "@/utils/token";

export default {
  name: "Large",
  data() {
    return {
      count: {
        new: 0,
        all: 0,
        dim: 0,
        not: 0,
        master: 0
      },
      id: null,
      common_mode: [
        {
          mode: "new",
          icon: "calendar-star",
          name: "新的"
        },
        {
          mode: "all",
          icon: "border-all",
          name: "全部"
        }
      ],
      // ("init", "新建"),
      //     ("not", "不会"),
      //     ("dim", "模糊"),
      //     ("master", "会")
      level_mode: [
        {
          mode: "not",
          color: "rgba(255, 71, 87, 1)",
          icon: "coin",
          name: "不会"
        }, {
          mode: "dim",
          color: "rgba(255, 186, 0, 1)",
          icon: "braille",
          name: "模糊"
        }, {
          mode: "master",
          color: "rgb(70,201,58)",
          icon: "coin-stack",
          name: "会"
        }
      ]
    }
  },
  async created() {
    let _id = this.$route.query.id
    this.id = _id
    await this.getAllCount()
  },
  methods: {
    async getAllCount() {
      let user = getUID()
      let questiondb = this.id

      let res = await this.$apollo.query({
        query: userQuestionListNewCount,
        variables: {
          user: user,
          questiondb: questiondb
        },
        fetchPolicy: 'no-cache',
      })
      this.count.new = res['data']['userQuestionListNew']['totalCount']

      res = await this.$apollo.query({
        query: userQuestionListAllCount,
        variables: {
          user: user,
          questiondb: questiondb
        },
        fetchPolicy: 'no-cache',
      })
      this.count.all = res['data']['userQuestionListAll']['totalCount']

      let memoryGradeList = ['dim', 'not', 'master']
      for (let i = 0; i < memoryGradeList.length; i++) {
        res = await this.$apollo.query({
          query: userQuestionListCount,
          variables: {
            user: user,
            questiondb: questiondb,
            memoryGrade: memoryGradeList[i]
          },
          fetchPolicy: 'no-cache',
        })
        this.count[memoryGradeList[i]] = res['data']['userQuestionList']['totalCount']
      }
    }
  }
}
</script>

<style scoped>
.learn-mode {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 3rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
  display: flex;
  flex-direction: column;
}

.subheading {
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgb(44, 62, 80);
  margin-top: 0;
}

.item {
  display: flex;
}
</style>
