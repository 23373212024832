import {gql} from "apollo-boost";

export const questionList = gql`query ($qdbid: ID!){
    questionList(questiondb: $qdbid){
        edges{
            node{
                id,
                stem
            }
        }
    }
}`

export const questionDatabaseCreate = gql`mutation ($name: String!, $des: String, $uid: ID!, $isShare: Boolean, $isPublic: Boolean){
    questionDatabaseCreate(input: {name: $name, description: $des, user: $uid, isShare: $isShare, isPublic: $isPublic}){
        questionDataBase{
            id,
            name,
            description
        }
    }
}`

export const questionDatabasePatch = gql`mutation ($id: ID!, $name: String!, $des: String, $uid: ID!, $isShare: Boolean, $isPublic: Boolean){
    questionDatabasePatch(id: $id, input: {name: $name, description: $des, user: $uid, isShare: $isShare, isPublic: $isPublic}){
        questionDataBase{
            id
        }
    }
}`
