import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import router from "@/config/router"; //Vuesax styles
import VueApollo from 'vue-apollo'
import {apolloProvider} from "@/utils/apollo";
import Vant, {Lazyload} from 'vant';
import 'vant/lib/index.css';
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import VuePageTransition from 'vue-page-transition'

Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
  lazyComponent: true,
});
Vue.use(VuePageTransition)
Vue.use(Vuesax, {
  // options here
})
Vue.use(Vant);
Vue.use(VueApollo)

Vue.use(VueRouter)

Vue.config.productionTip = false
new Vue({
  router,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
