import {ApolloClient} from 'apollo-client'
import {HttpLink} from 'apollo-link-http'
import {InMemoryCache} from 'apollo-cache-inmemory'
import commonConfig from "@/config/common";
import VueApollo from 'vue-apollo'
import {getToken, removeToken} from "@/utils/token";
import {onError} from "apollo-link-error";
import {from} from 'apollo-link'
import {setContext} from 'apollo-link-context';


// 与 API 的 HTTP 连接
const httpLink = new HttpLink({
  // 你需要在这里使用绝对路径
  uri: commonConfig.graphqlURL,
})

const authLink = setContext((_, {headers}) => {
  const token = getToken("access_token")
  return {
    headers: {
      ...headers,
      "Authorization": token ? token : "",
    },
  };
});

// 缓存实现
const cache = new InMemoryCache()
const errorLink = onError(({graphQLErrors, networkError}) => {
  console.log(graphQLErrors)
  const {message} = graphQLErrors[0]
  if (message === "401") {
    removeToken()
    window.location.reload()
  }
  console.log(networkError)

})
const apollo = new ApolloClient({
  // 你需要在这里使用绝对路径
  link: from([
    errorLink,
    authLink,
    httpLink,
  ]),
  // uri: commonConfig.graphqlURL,
  cache
})

export const apolloProvider = new VueApollo({
  defaultClient: apollo,
  // 所有智能查询和订阅的全局错误处理函数
  defaultOptions: {
    // 详见 'apollo' 的定义
    // 例如：默认查询选项
    $query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    $mutate: {
      errorPolicy: 'all'
    }
  },
  errorHandler(error) {
    console.log('Global error handler')
    console.error(error)
  },
  connectToDevTools: true
})


export default apollo;
