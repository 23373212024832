import Home from "@/pages/Home";
import Test from "@/pages/TestMode/LargeTest";
import QDBQList from "@/pages/QuestionDB/QDBQList";
import Large from "@/pages/TestMode/Large";
import SignIn from "@/pages/Sign/SignIn";
import SignUp from "@/pages/Sign/SignUp";
import QDBCreate from "@/pages/QuestionDB/QDBCreate";
import QDBEdit from "@/pages/QuestionDB/QDBEdit";
import QCreate from "@/pages/Question/QCreate";
import QEdit from "@/pages/Question/QEdit";
import StoreList from "@/pages/Store/StoreList";
import More from "@/pages/Other/More";


const routes = [
  {
    path: '/',
    component: Home
  }, {
    path: "/test",
    component: Test,
    meta: { transition: 'fade-in-left' },
  }, {
    path: "/qdb/create",
    component: QDBCreate,
    meta: {transition: 'fade-in-left'},
  }, {
    path: "/qdb/edit",
    component: QDBEdit,
    meta: {transition: 'fade-in-left'},
  }, {
    path: "/qdb/q/list",
    component: QDBQList,
    meta: {transition: 'fade-in-left'},
  }, {
    path: "/q/create",
    component: QCreate,
    meta: {transition: 'fade-in-left'},
  }, {
    path: "/q/edit",
    component: QEdit,
    meta: {transition: 'fade-in-left'},
  }, {
    path: "/test-mode/large",
    component: Large
  }, {
    path: "/sign/in",
    component: SignIn
  }, {
    path: "/sign/up",
    component: SignUp
  }, {
    path: "/store/list",
    component: StoreList
  }, {
    path: "/more",
    component: More
  }
]

export default routes;
