<template>
  <div class="more">
    <header>
      <h1># 更多</h1>
    </header>
    <div class="moreGrid card">
      <div class="center" @click="logout()">
        <img :src="require(`../../static/img/icons8_exit_96px.png`)" class="icon"/>
        <span class="iconText">注销</span>
      </div>
      <div class="center" @click="refresh()">
        <img :src="require(`../../static/img/icons8_rotate_right_96px.png`)" class="icon"/>
        <span class="iconText">刷新</span>
      </div>
      <div class="center" @click="$router.push({path:'/qdb/create'})">
        <img :src="require(`../../static/img/icons8_bulleted_list_96px.png`)" class="icon"/>
        <span class="iconText">题库+</span>
      </div>
      <div class="center" @click="$router.push({path:'/q/create'})">
        <img :src="require(`../../static/img/icons8_questions_96px.png`)" class="icon"/>
        <span class="iconText">题目+</span>
      </div>
    </div>
  </div>
</template>

<script>
import {removeToken} from "@/utils/token";

export default {
  name: "More",
  methods: {
    refresh() {
      window.location.reload()
    },
    logout() {
      removeToken()
      this.$router.push({path: "/sign/in"})
    }
  }
}
</script>

<style scoped>
.card {
  margin: 0 1rem 1rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

header {
  color: #1e1e1e;
  padding: 0 1rem;
}

.moreGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2.5rem;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 4px;
}

.iconText {
  font-size: 0.8rem;
}
</style>
