import gql from "graphql-tag";

export const userQuestionListNewCount = gql`query ($user: ID!, $questiondb:ID!){
    userQuestionListNew(user: $user,
        questiondb: $questiondb){
        totalCount
    }
}`
export const userQuestionListAllCount = gql`query ($user: ID!, $questiondb:ID!){
    userQuestionListAll(user: $user,
        questiondb: $questiondb){
        totalCount
    }
}`
export const userQuestionListCount = gql`query ($questiondb: ID!, $user: ID!,$memoryGrade: String){
    userQuestionList(questiondb: $questiondb, user: $user, memoryGrade: $memoryGrade){
        totalCount
    }
}`
