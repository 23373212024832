<template>
  <div class="contain">
    <header>
      <h1 v-if="mode==='create'"># 题库创建</h1>
      <h1 v-if="mode==='edit'"># 题库编辑</h1>
    </header>
    <div class="card">
      <div class="subheading">
        题库名
      </div>
      <vs-input v-model="form.name"></vs-input>
    </div>
    <div class="card">
      <div class="subheading">
        分享+
      </div>

      <div class="item between">
        <span>上架书馆共享</span>
        <vs-switch v-model="form.isPublic" warn>
          <template #on>
            <box-icon color="#ffffff" name='door-open'></box-icon>
          </template>
          <template #off>
            <box-icon name='unlink'></box-icon>
          </template>
        </vs-switch>
      </div>
      <div class="item between">
        <span>通过暗语分享</span>
        <vs-switch v-model="form.isShare" color="#54e2cf">
          <template #on>
            <box-icon color="#ffffff" name='paper-plane'></box-icon>
          </template>
          <template #off>
            <box-icon name='lock'></box-icon>
          </template>
        </vs-switch>
      </div>
      <div v-show="id&&form.isShare" class="item between">
        <span style="font-size: 12px">暗语:{{ getCNKey(id) }}</span>
        <vs-button flat @click="copy( getCNKey(id) )">复制</vs-button>
      </div>
    </div>
    <div class="card">
      <div class="subheading">
        描述
      </div>
      <van-field
          v-model="form.description"
          autosize
          maxlength="50"
          placeholder="请输入题库描述"
          rows="2"
          show-word-limit
          type="textarea"
      />
      <div v-if="mode==='create'" class="item">
        <vs-button floating size="mini" success @click="create">
          <box-icon color='#ffffff' name='check-double'></box-icon>
          <span class="btn-text">创建</span></vs-button>
      </div>
      <div v-if="mode==='edit'" class="item row">
        <vs-button floating @click="patch">
          <box-icon color='#ffffff' name='save'></box-icon>
          <span class="btn-text">保存</span></vs-button>
        <vs-button-group>
          <vs-button danger flat size="mini" @click="remove()">
            <span class="btn-text">删除</span>
          </vs-button>
          <vs-button flat size="mini" success
                     @click="$router.push({path:'/qdb/create'})">
            <span class="btn-text">再来一个</span>
          </vs-button>
        </vs-button-group>

      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import {getUID} from "@/utils/token";
import {questionDatabaseCreate, questionDatabasePatch} from "@/pages/QuestionDB/graphql";
import {Plugins} from '@capacitor/core';
import commonConfig from "@/config/common";

export default {
  name: "QDBCE",//题库创建或编辑,
  props: {
    mode: {
      type: String,
    }
  },
  data() {
    return {
      id: null,
      form: {
        name: null,
        description: null,
        isShare: false,
        isPublic: false
      }
    }
  },
  async created() {
    if (this.mode === 'edit') {
      let _id = this.$route.query.id
      this.id = _id
      await this.get()
    }
  },
  methods: {
    /**
     * 拷贝到剪切板
     * @param v 拷贝的值
     */
    copy(v) {
      const {Clipboard} = Plugins;
      Clipboard.write({
        string: v
      });
      this.$toast("拷贝成功")
    },
    remove() {
      const form = this.form
      this.$apollo.mutate({
        mutation: gql`mutation ($id: ID!){
          questionDatabaseDelete(id: $id){
            found
          }}`,
        variables: {
          id: form.id,
        }
      }).then(() => {
        this.$toast("删除成功🍂")
        this.$router.replace({
          path: "/",
        })
      })
    },
    async get() {
      await this.$apollo.query({
        query: gql`query ($id: ID!){
            questionDatabase(id: $id){
                id,
                name,
                description,
                isPublic,
                isShare
            }
        }`,
        fetchPolicy: 'no-cache',
        variables: {
          id: this.id,
        }
      })
          .then(({data}) => {
            this.form = data['questionDatabase']
          })
    },
    patch() {
      const form = this.form
      this.form = {name: null, description: null}
      if (form.name === null || form.name === "") {
        this.$toast("题库名称不能为空哦🈳️️")
        return
      }
      this.$apollo.mutate({
        mutation: questionDatabasePatch,
        variables: {
          id: form.id,
          name: form.name,
          des: form.description,
          isPublic: form.isPublic,
          isShare: form.isShare,
          uid: getUID()
        }
      }).then(() => {
        this.$toast("题库修改完成😄️")
        this.$router.replace({
          path: "/",
        })
      })
    },
    create() {
      const form = this.form
      this.form = {name: null, description: null}
      if (form.name === null || form.name === "") {
        this.$toast("题库名称不能为空哦🈳️️")
        return
      }
      this.$apollo.mutate({
        mutation: questionDatabaseCreate,
        variables: {
          name: form.name,
          des: form.description,
          isPublic: form.isPublic,
          isShare: form.isShare,
          uid: getUID()
        },
        update: (store, {data: {questionDatabaseCreate}}) => {
          this.$toast("题库创建成功😄️")
          this.$router.replace({
            path: "/qdb/edit",
            query: {
              id: questionDatabaseCreate['questionDataBase']['id']
            }
          })
        }
      })
    },
    getCNKey(v) {
      if (typeof v !== "string")
        return
      let out = ""
      let cn = unescape(commonConfig.cnkey)
      for (let i = 0; i < v.length; i++) {
        out += cn[v[i].charCodeAt()]
      }
      return out;
    }
  }
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 1.2rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}

.subheading {
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgb(44, 62, 80);
  margin-top: 0;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
