<template>
  <div class="contain">
    <header>
      <h1># 注册</h1>
    </header>
    <div class="card">
      <div class="subheading">用户名</div>
      <div class="item">
        <vs-input placeholder="4-16个字母/汉字/数字/_" v-model="username">
          <template #icon>
            <box-icon name='happy'></box-icon>
          </template>
        </vs-input>
      </div>
      <div class="item">
        <div class="subheading">密码</div>
        <vs-input v-model="password" type="password">
          <template #icon>
            <box-icon name='key'></box-icon>
          </template>
        </vs-input>
      </div>
      <div class="item">
        <div class="subheading">确认密码</div>
        <vs-input v-model="confirmPassword" type="password">
          <template #icon>
            <box-icon name='check-double'></box-icon>
          </template>
        </vs-input>
      </div>
    </div>
    <div class="center">
      <vs-button floating @click="signUp">
        <box-icon color='#ffffff' name='game'></box-icon>
        <span class="btn-text">注 册</span></vs-button>
    </div>
    <div class="center top-4">
      <vs-button flat success @click="$router.push({path: '/sign/in'})">已经注册？立即登录</vs-button>
    </div>
  </div>
</template>

<script>
import {createUser} from "@/pages/Sign/graphql";
import {usernameRegex} from "@/utils/token";

export default {
  name: "SignUp",
  data() {
    return {
      username: "",
      password: "",
      confirmPassword: ""
    }
  },
  methods: {
    signUp() {
      const username = this.username
      const password = this.password
      if(!usernameRegex.exec(username)){
        this.$toast("用户名不太正确哦😮️")
        return
      }
      if(password===""||password===null){
        this.$toast("密码不能为空哦😐️")
        return;
      }
      if (password !== this.confirmPassword) {
        this.$toast("两次密码不一样🙅🏻")
        return
      }
      this.username = ""
      this.password = ""
      this.$apollo.mutate({
        mutation: createUser,
        variables: {
          username: username,
          password: password,
        },
      }).then(() => {
        this.$toast("恭喜你入驻新世界🌐️")
        this.$router.replace({
          path: "/sign/in"
        })
      }).catch(()=>{this.$toast("注册不正常😶️")})
    },


  }
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.stem {
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}


.subheading {
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgb(44, 62, 80);
  margin-top: 0;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.top-4 {
  margin-top: 4rem;
}
</style>
