<template>
  <div class="storeList">
    <header>
      <h1># 书馆</h1>
      <vs-button flat shadow size="mini" @click="showKey=!showKey">暗语</vs-button>
    </header>
    <vs-dialog v-model="showKey" blur>
      <template #header>
        来一句<b>暗语</b>
      </template>
      <vs-input v-model="key" placeholder="小点声"></vs-input>
      <template #footer>
        <div class="right">
          <vs-button transparent @click="getByKey()">告诉你</vs-button>
        </div>
      </template>
    </vs-dialog>
    <div id="card-list">
      <div v-show="quesDBList.length===0" class="card">
        <van-empty description="你似乎来到了没有知识存在的荒原"/>
      </div>
      <div v-for="(db, dbIndex) in quesDBList" :key="dbIndex" class="card-contain">
        <vs-card type="3">
          <template #title>
            <h2>{{ db['node']['name'] }}</h2>
          </template>
          <template #text>
            <p class="description">
              {{ db['node']['description'] }}
            </p>
          </template>
          <template #img>
            <img style="width: 5rem" :src="require(`@/static/img/default-cover${dbIndex%10}.jpg`)"
                 alt="db['node']['name']">
          </template>
          <template #interactions>
            <vs-button danger floating gradient @click="clone(db['node']['id'])">
              <box-icon color='#ffffff' name='package'/>
              <span class="btn-text">克隆</span>
            </vs-button>
          </template>
        </vs-card>

      </div>

    </div>
  </div>
</template>

<script>

import {getUID} from "@/utils/token";
import {questionDatabaseClone, questionDatabaseList} from "@/graphql/questiondb";
import commonConfig from "@/config/common";

export default {
  name: "Home",
  props: {
    id: {}
  },
  data() {
    return {
      key: null,
      showKey: false,
      quesDBList: []
    }
  },
  async created() {
    await this.get()
  },
  methods: {
    async getByKey() {
      let _k = this.key
      if (_k === "" || typeof _k !== "string") {
        this.showKey = !this.showKey
        this.$toast("暗语怕是有问题吧🤨")
        return
      }
      let out = ""
      let cn = unescape(commonConfig.cnkey)
      for (let i = 0; i < _k.length; i++) {
        out += String.fromCharCode(cn.indexOf(_k[i]))
      }
      this.showKey = !this.showKey
      await this.clone(out)
    },
    async get() {
      let uid = getUID()
      if (uid) {
        await this.$apollo.query({
          query: questionDatabaseList,
          variables: {
            user: uid,
            isPublic: true
          },
          fetchPolicy: "no-cache",
        }).then(({data}) => {
          this.quesDBList = data['questionDatabaseList']['edges']
        })
      }
    },
    async clone(questiondb) {
      let user = getUID()
      try {
        let {data} = await this.$apollo.mutate({
          mutation: questionDatabaseClone,
          variables: {
            questiondb: questiondb,
            user: user
          }
        })
        let id = data['questionDatabaseClone']['questionDatabase']['id']
        this.$toast("克隆成功")
        this.$router.push({
          path: '/qdb/edit',
          query: {
            id: id
          }
        })
      } catch (e) {
        this.$toast("克隆失败")
      }

    }
  }
}
</script>

<style scoped>
header {
  padding: 0 1rem;
  color: #1e1e1e;

}

header {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.btn-text {
  padding-left: 5px;
  font-weight: 700;
}


.storeList {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

.card-contain {
  margin: 0 auto 3rem auto;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
  display: flex;
  align-items: center;
  flex-direction: column;
}

#card-list {
  display: grid;
  padding: 20px 10px;
}

@media screen and (min-width: 435px) {
  #card-list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 870px) {
  #card-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 1305px) {
  #card-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1740px) {
  #card-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 2175px) {
  #card-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media screen and (min-width: 2610px) {
  #card-list {
    grid-template-columns: repeat(6, 1fr);
  }
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.description {
  width: 100px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.right {
  display: flex;
  justify-content: flex-end;
}
</style>
