<template>
  <div class="contain">
    <header>
      <h1># 题库的题目</h1>
      <box-icon name='layer-plus' @click="$router.push({path:'/q/create', query:{qdbid: id}})"></box-icon>
    </header>
    <div>
      <div v-for="(q, qIndex) in questionList" :key="qIndex" class="card">
        <div class="stem ql-container ql-bubble">
          <div class="ql-editor" v-html="q['node']['stem']"></div>
        </div>
        <vs-button floating @click="$router.push({path:'/q/edit',query: {id: q['node']['id']}})">
          <box-icon color='#ffffff' name='edit-alt'></box-icon>
          <span class="btn-text">编辑</span>
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>


import {questionList} from "@/pages/QuestionDB/graphql";

export default {
  name: "QDBQList",//题库下的题目列表,
  data() {
    return {
      id: null,
      questionList: [],
    }
  },
  async created() {

    let _id = this.$route.query.id
    this.id = _id
    await this.getQuestionList()
  },
  methods: {
    async getQuestionList() {
      await this.$apollo.query({
        query: questionList,
        fetchPolicy: 'no-cache',
        variables: {
          qdbid: this.id
        }
      }).then(({data}) => {
        const {edges} = data['questionList'];
        this.questionList = edges
      })
    }
  }
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.stem {
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}
</style>
