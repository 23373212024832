import gql from "graphql-tag";

export const signIn = gql`mutation signIn($username: String!, $password: String!){
    tokenAuth(username: $username, password: $password){
        token
    }}`

export const createUser = gql`mutation ($username: String!, $password: String!){
    createUser(username: $username, password: $password){
        user{
            username
        }
    }
}`
