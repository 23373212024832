export function getToken(TokenKey) {
  return localStorage.getItem(TokenKey)
}

export function setAccessToken(access_token) {
  localStorage.setItem('access_token', `Bearer ${access_token}`)
}

export function setTokens(access_token, refresh_token = null) {
  localStorage.setItem('access_token', `JWT ${access_token}`)
  localStorage.setItem('refresh_token', `${refresh_token}`)
}

export function removeToken() {
  localStorage.clear()
  // localStorage.removeItem('refresh_token')
}
export function getUID(){
  return localStorage.getItem("uid")
}
export const usernameRegex = /^([\u4e00-\u9fa5]{2,4})|([A-Za-z0-9_]{4,16})|([a-zA-Z0-9_\u4e00-\u9fa5]{3,16})$/;
