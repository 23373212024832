import gql from "graphql-tag";

export const questionCreate = gql`mutation questionCreate($stem: String!, $ans: String!, $QDBId: ID!){
    questionCreate(input: {stem: $stem, answer: $ans, questiondb: $QDBId}){
        question{
            id
        }
    }
}`

export const questionDelete = gql`mutation questionDelete($qid: ID!){
    questionDelete(id: $qid){
        found
    }
}`

export const questionGet = gql`query questionGet($qid: ID!){
    question(id: $qid){
        stem,
        answer,
        questiondb{
            id,
            name
        }
    }
}`
export const questionPatch = gql`mutation questionPatch($qid: ID!, $stem: String!, $ans: String!, $QDBId: ID!){
    questionPatch(id: $qid, input: {stem: $stem, answer: $ans, questiondb: $QDBId}){
        question{
            id
        }
    }
}`


