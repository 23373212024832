<template>
  <div class="contain">
    <header>
      <h1 v-if="mode==='create'"># 题目创建</h1>
      <h1 v-if="mode==='edit'"># 题目编辑</h1>
    </header>
    <div>
      <div class="card">
        <div class="subheading">
          所属题库
        </div>
        <van-field
            :value="form.questiondb.name"
            clickable
            placeholder="点击选择"
            readonly
            @click="qdbPicker.show = true"
        />
        <van-popup v-model="qdbPicker.show" :position="qdbPicker.position">
          <van-picker
              :columns="qdbPicker.columns"
              show-toolbar
              @confirm="qdbPickerConfirm"
          />
        </van-popup>
      </div>
      <div class="card">
        <div class="subheading">
          题干
        </div>
        <div id="stem-editor"></div>
      </div>
      <div class="card">
        <div class="subheading">
          答案
        </div>
        <div id="answer-editor"></div>
      </div>
      <div class="card">
        <div v-if="mode==='create'" class="item">
          <vs-button floating success @click="create">
            <box-icon color='#ffffff' name='check-double'></box-icon>
            <span class="btn-text">创建</span></vs-button>
        </div>

        <div v-if="mode==='edit'" class="item row">
          <vs-button floating size="mini" @click="patch">
            <box-icon color='#ffffff' name='save'></box-icon>
            <span class="btn-text">保存</span>
          </vs-button>
          <vs-button-group>
            <vs-button danger flat size="mini" @click="remove()">
              <span class="btn-text">删除</span>
            </vs-button>
            <vs-button flat size="mini" success
                       @click="$router.push({path:'/q/create', query:{qdbid: form.questiondb.id}})">
              <span class="btn-text">再来一个</span>
            </vs-button>
          </vs-button-group>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {questionCreate, questionDelete, questionGet, questionPatch} from "@/pages/Question/graphql";
import Quill from "quill"
import commonConfig from "@/config/common";
import {getUID} from "@/utils/token";
import {questionDatabaseList} from "@/graphql/questiondb";

export default {
  name: "QCE", //题目创建或编辑
  props: {
    mode: {
      type: String
    }
  },
  async created() {
    await this.getQDB()

    // this.form
    if (this.mode === 'edit') {
      let _id = this.$route.query.id
      this.id = _id
      await this.get()
    }
    this.init_editor()
    let {qdbid} = this.$route.query
    if (qdbid) {
      this.form.questiondb.id = qdbid
      this.form.questiondb.name = this.findQDBName(qdbid)
    }
  },
  data() {
    return {
      //编辑器
      quill: {
        stem: null,
        answer: null
      },
      qdbPicker: {
        columns: null,
        show: false,
        position: "bottom"
      },
      loading: true,
      id: null,
      qdb_column: null,
      questionDBList: [],
      form: {
        stem: null,
        answer: null,
        questiondb: {
          id: [],
          name: null
        },
      }
    }
  },
  methods: {
    /**
     * 根据题库id查找name
     */
    findQDBName(id) {
      let q = this.questionDBList;
      for (let i = 0; i < q.length; i++) {
        if (id === q[i]['node']['id'])
          return q[i]['node']['name']
      }
      return ""
    },
    init_editor() {
      let that = this;
      let stem = new Quill("#stem-editor", {
        modules: {toolbar: commonConfig.toolbar},
        theme: "bubble",
        placeholder: "请输入题干"
      })

      let answer = new Quill("#answer-editor", {
        modules: {toolbar: commonConfig.toolbar},
        theme: "bubble",
        placeholder: "请输入答案"
      })

      this.changeLinkText(stem)
      this.changeLinkText(answer)
      this.quill.stem = stem
      this.quill.answer = answer

      this.quill.stem.root.innerHTML = this.form.stem;
      this.quill.answer.root.innerHTML = this.form.answer;

      this.quill.stem.on("text-change", function () {
        that.form.stem = that.quill.stem.root.innerHTML
      })
      this.quill.answer.on("text-change", function () {
        that.form.answer = that.quill.answer.root.innerHTML
      })
    },
    /**
     * 改变默认超链接placeholder文本
     * @param quill
     */
    changeLinkText(quill) {
      let tooltip = quill.theme.tooltip;
      let input = tooltip.root.querySelector("input[data-link]");
      input.dataset.link = 'chenkeyan.com';
    },
    qdbPickerConfirm(value, index) {
      this.form.questiondb.id = this.questionDBList[index]['node']['id']
      this.form.questiondb.name = this.questionDBList[index]['node']['name']
      this.qdbPicker.show = false
    },
    create() {
      const form = this.form
      this.$apollo.mutate({
        mutation: questionCreate,
        variables: {
          stem: form.stem,
          ans: form.answer,
          QDBId: form.questiondb.id
        },
        fetchPolicy: "no-cache",
      }).then(({data}) => {
        this.$toast("创建了一个题目😄️")
        this.$router.replace({
          path: "/q/edit",
          query: {
            id: data['questionCreate']['question']['id']
          }
        })
      }).catch(() => {
        this.$toast("好像不太正常，请检查一下吧")
      })
    },
    remove() {
      this.$apollo.mutate({
        mutation: questionDelete,
        variables: {
          qid: this.id
        },
      }).then(() => {
        this.$toast("移除了一个题目🍃")
        this.$router.replace({path: "/"})
      }).catch(() => {
        this.$toast("好像不太正常，请检查一下吧")
      })
    },
    async get() {
      await this.$apollo.query({
        query: questionGet,
        fetchPolicy: "no-cache",
        variables: {
          qid: this.id
        },
      }).then(({data}) => {
        this.form = data['question']
      }).catch(() => {
        this.$toast("好像不太正常，请检查一下吧")
      })
    },
    patch() {
      const form = this.form
      this.$apollo.mutate({
        mutation: questionPatch,
        variables: {
          qid: this.id,
          stem: form.stem,
          ans: form.answer,
          QDBId: form.questiondb.id
        },
      }).then(() => {
        this.$toast("题目编辑保存成功😄️")
      }).catch(() => {
        this.$toast("好像不太正常，请检查一下吧")
      })
    },
    async getQDB() {
      await this.$apollo.query({
        query: questionDatabaseList,
        variables: {
          user: getUID()
        },
        fetchPolicy: "no-cache",
      }).then(({data}) => {
        const {edges} = data['questionDatabaseList']
        let qdb_column = []
        for (let i = 0; i < edges.length; i++) {
          qdb_column.push(edges[i]['node']['name'])
        }
        this.qdbPicker.columns = qdb_column
        this.questionDBList = edges
        this.loading = false
      })
    },

  }
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.subheading {
  font-size: 1.3rem;
  padding-top: 1rem;
  padding-left: 10px;
  padding-bottom: 0;
  color: rgb(44, 62, 80);
  margin-top: 0;
}

.card {
  margin: 0 1rem 1rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.right {
  display: flex;
  justify-content: flex-end;
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}
</style>
