import gql from "graphql-tag";

export const questionDatabaseList = gql`query ($user: ID, $isPublic: Boolean){
    questionDatabaseList(user: $user, isPublic: $isPublic){
        edges{
            node{
                id,
                name,
                description,
                __typename
            },
            __typename
        }
    }
}`
export const questionDatabaseClone = gql`mutation clone($questiondb: ID!, $user: ID!){
    questionDatabaseClone(questiondb:$questiondb, user:$user){
        questionDatabase{
            id
        }
    }
}`
