<template>
  <div class="contain">
    <header>
      <h1># 学习中</h1>
    </header>
    <div>
      <van-swipe ref="swipe" :loop="false" :show-indicators="false" @change="swipeChange">
        <van-swipe-item v-for="(q, index) in questionList" :key="index">
          <div class="swipe-item">
            <div class="card">
              <div v-if="!showAnswerList[index]['show']" class="ql-container ql-bubble">
                <div class="ql-editor" v-html="q['node']['question']['stem']"></div>
              </div>
              <div v-else class="ql-container ql-bubble">
                <div class="ql-editor" v-html="q['node']['question']['answer']"></div>
              </div>
              <div class="between">
                <van-tag color="#f4f7f8" size="medium" text-color="rgb(44, 62, 80)">
                  {{ index + 1 }}/{{ questionList.length }}
                </van-tag>
                <div class="right-small-icon">
                  <box-icon class="edit"
                            name='edit-alt'
                            @click="$router.replace({path:'/q/edit',query:{id: q['node']['question']['id']}})"/>
                  <van-tag v-if="answerList[index]['memoryGrade']==='NOT'" plain type="danger">不会</van-tag>
                  <van-tag v-else-if="answerList[index]['memoryGrade']==='DIM'" plain type="warning">模糊</van-tag>
                  <van-tag v-else-if="answerList[index]['memoryGrade']==='MASTER'" plain type="success">会</van-tag>
                </div>
              </div>
            </div>
            <div class="quse-btn">
              <!--显示答案-->
              <vs-button v-if="!showAnswerList[index]['show']" circle flat size="xlarge"
                         @click="showAnswerList[index]['show'] = true">
                <box-icon color='#195bff' name='hide'></box-icon>

              </vs-button>
              <!--显示题干-->
              <div v-else class="quse-btn">
                <vs-button circle floating size="xlarge" @click="showAnswerList[index]['show'] = false">
                  <box-icon color='#ffffff' name='show-alt'></box-icon>
                </vs-button>
                <div class="row" style="margin-top: 4rem">
                  <vs-button color="rgba(255, 71, 87, 1)" floating size="large" @click="setMemoryGrade(index, 'NOT')">
                    <box-icon color="#fff" name='coin'></box-icon>
                    不会
                  </vs-button>

                  <vs-button color="rgba(255, 186, 0, 1)" floating size="large" @click="setMemoryGrade(index, 'DIM')">
                    <box-icon color="#fff" name='braille'></box-icon>
                    模糊
                  </vs-button>
                  <vs-button color="rgb(70,201,58)" floating size="large" @click="setMemoryGrade(index, 'MASTER')">
                    <box-icon color="#fff" name='coin-stack'></box-icon>
                    会
                  </vs-button>
                </div>
              </div>

            </div>
          </div>
        </van-swipe-item>
        <van-swipe-item>
          <div class="swipe-item">
            <div class="card">恭喜你复习完了.</div>
          </div>
          <vs-dialog v-model="isFinish" blur>
            <p class="center tip">是否返回学习模式？</p>

            <template #header>
              <h4>学习数据已保存</h4>
            </template>
            <template #footer>
              <div class="around">
                <vs-button block floating @click="$router.replace({path: '/test-mode/large', query:{id}})">
                  好的
                </vs-button>
              </div>
            </template>
          </vs-dialog>
        </van-swipe-item>
      </van-swipe>

    </div>
  </div>
</template>

<script>
import {
  userQuestionBatchPatch,
  userQuestionList,
  userQuestionListAll,
  userQuestionListNew
} from "@/pages/TestMode/graphql";
import {getUID} from "@/utils/token";

export default {
  name: "LargeTest",
  data() {
    return {
      isFinish: false,
      mode: null,
      id: null,
      questionList: [],
      showAnswerList: [],
      answerList: []
    }
  },
  async created() {
    this.id = this.$route.query.id
    this.mode = this.$route.query.mode
    await this.getQuestionListonList()
  },
  methods: {
    async getQuestionListonList() {
      const memoryModeList = ['not', 'dim', 'master']
      if (this.mode === "new") {
        const {data} = await this.$apollo.query({
          query: userQuestionListNew,
          fetchPolicy: 'no-cache',
          variables: {
            qdbid: this.id,
            uid: getUID()
          }
        })
        const {edges} = data['userQuestionListNew']
        this.questionList = edges
      } else if (this.mode === "all") {
        const {data} = await this.$apollo.query({
          query: userQuestionListAll,
          fetchPolicy: 'no-cache',
          variables: {
            qdbid: this.id,
            uid: getUID()
          }
        })
        const {edges} = data['userQuestionListAll']
        this.questionList = edges
      } else if (memoryModeList.includes(this.mode)) {
        const {data} = await this.$apollo.query({
          query: userQuestionList,
          fetchPolicy: 'no-cache',

          variables: {
            memoryGrade: this.mode,
            qdbid: this.id,
            uid: getUID()
          }
        })
        const {edges} = data['userQuestionList']
        this.questionList = edges
      }

      let answerList = []
      let showAnswerList = []
      for (let i = 0; i < this.questionList.length; i++) {
        answerList.push({
          memoryGrade: this.questionList[i]['node']['memoryGrade'],
          id: this.questionList[i]['node']['id'],
        })
        showAnswerList.push({show: false})
      }
      this.showAnswerList = showAnswerList
      this.answerList = answerList
    },
    setMemoryGrade(index, memoryGrade) {
      this.answerList[index]['memoryGrade'] = memoryGrade
      this.$refs.swipe.next()
    },
    async swipeChange(index) {
      if (index === this.answerList.length) {
        await this.$apollo.mutate({
          mutation: userQuestionBatchPatch,
          variables: {
            input: this.answerList
          }
        })
        this.isFinish = true
      }
    }
  }
}
</script>

<style scoped>
.contain {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #f4f7f8;;
}

header {
  padding: 0 1rem;
}

header h1 {
  font-size: 1.5rem;
  color: rgb(44, 62, 80);
}

.card {
  margin: 0 1rem 1.5rem 1rem;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px 20px 20px 20px;
  border-bottom: 0 solid rgba(0, 0, 0, .03);
}

.item {
  margin: 1.5rem 0;
}

.row {
  display: flex;
  justify-content: space-between;
}

.stem {
  margin-bottom: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.btn-text {
  font-size: 15px;
  margin-left: 2px;
}

.swipe-item {
  min-height: 100vh;
}

.quse-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.around {
  display: flex;
  justify-content: space-around;
}

.between {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tip {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.right-small-icon {
  display: flex;
  align-items: center;
}

.right-small-icon .edit {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
</style>
