const commonConfig = {
  graphqlURL: process.env.VUE_APP_GRAPHQL_URL,
  toolbar: [
    [
      'bold', 'italic',
      {'header': 1}, {'header': 2},
      {"color": ['#FFF','#3857ff', "#66cb28", "#ee4459", '#f5bb00', '#000000']},
      {"background":['#FFF','#3857ff', "#66cb28", "#ee4459", '#f5bb00', '#000000']},
      {'list': 'ordered'}, {'list': 'bullet'},
      {'indent': '-1'}, {'indent': '+1'},
      'blockquote', 'code-block'
    ]],
    // cnkey:"\u79e6\u65f6\u660e\u6708\u6c49\u5173\u4e07\u91cc\u957f\u5f81\u4eba\u672a\u8fd8\u4f46\u4f7f\u9f99\u57ce\u98de\u5c06\u5728\u4e0d\u6559\u80e1\u9a6c\u5ea6\u9634\u5c71\u6625\u7720\u89c9\u6653\u5904\u95fb\u557c\u9e1f\u591c\u6765\u98ce\u96e8\u58f0\u82b1\u843d\u77e5\u591a\u5c11\u541b\u81ea\u6545\u4e61\u5e94\u4e8b\u65e5\u7eee\u7a97\u524d\u5bd2\u6885\u8457\u677e\u4e0b\u95ee\u7ae5\u5b50\u8a00\u5e08\u91c7\u836f\u53bb\u53ea\u6b64\u4e2d\u4e91\u6df1\u671d\u8f9e\u767d\u5e1d\u5f69\u95f4\u5343\u6c5f\u9675\u4e00\u4e24\u5cb8\u733f\u4f4f\u8f7b\u821f\u5df2\u8fc7\u91cd\u72ec\u5f02\u4e3a\u5ba2\u6bcf\u9022\u4f73\u8282\u500d\u601d\u4eb2\u9065\u5144\u5f1f\u767b\u9ad8\u904d\u63d2\u8331\u8438\u4f9d\u5c3d\u9ec4\u6cb3\u5165\u6d77\u6d41\u6b32\u7a77\u76ee\u66f4\u4e0a\u5c42\u697c\u8461\u8404\u7f8e\u9152\u5149\u676f\u996e\u7435\u7436\u50ac\u9189\u5367\u6c99\u573a\u83ab\u7b11\u53e4\u6218\u51e0\u56de"
    cnkey:"\u79e6\u65f6\u660e\u6708\u6c49\u5173\u4e07\u63d2\u957f\u5f81\u4eba\u672a\u821f\u4f46\u4f7f\u9f99\u57ce\u98de\u5c06\u5728\u4e0d\u6559\u80e1\u9a6c\u5ea6\u9634\u5c71\u6625\u7720\u89c9\u6653\u5904\u95fb\u557c\u9e1f\u591c\u6765\u7a97\u53bb\u767b\u82b1\u843d\u77e5\u591a\u5c11\u541b\u81ea\u6545\u4e91\u65e5\u4e8b\u5e94\u7eee\u98ce\u524d\u5bd2\u6885\u8457\u677e\u4e0b\u95ee\u7ae5\u5b50\u8a00\u5e08\u91c7\u836f\u5343\u53ea\u6b64\u95f4\u4e61\u6df1\u733f\u8fd8\u4f4f\u904d\u8282\u4e00\u96e8\u5144\u8fc7\u4e2d\u4e24\u5cb8\u671d\u767d\u8f7b\u8f9e\u5df2\u9675\u91cd\u72ec\u5f02\u4e3a\u5ba2\u6bcf\u9022\u4f73\u5f69\u500d\u601d\u4eb2\u9065\u6c5f\u5f1f\u58f0\u9ad8\u5e1d\u91cc\u8331\u8438\u4f9d\u5c3d\u9ec4\u6cb3\u5165\u6d77\u6d41\u6b32\u7a77\u76ee\u66f4\u4e0a\u5c42\u697c\u8461\u8404\u7f8e\u9152\u5149\u676f\u996e\u7435\u7436\u50ac\u9189\u5367\u6c99\u573a\u83ab\u7b11\u53e4\u6218\u51e0\u56de"
}
export default commonConfig;
