<template>
<q-c-e mode="create"></q-c-e>
</template>

<script>
import QCE from "@/pages/Question/components/QCE";
export default {
name: "QCreate",
  components: {QCE}
}
</script>

<style scoped>

</style>
